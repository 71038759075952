<script lang="ts" setup>

</script>

<template>
  <AppHeader />
  <main>
    <slot />
  </main>
  <AppFooter />

  <!-- <AppOtpModal :visible="show"/> -->
</template>

<style lang="css" scoped></style>
